import ProductCard from "../ProductCard/ProductCard";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
// import { RadioGroup } from "@headlessui/react";
import AddToCart from "../AddToCart/AddToCart";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoIosHeart } from "react-icons/io";
// import { Navigate, useNavigate } from "react-router-dom";
import { IoShareSocialOutline } from "react-icons/io5";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import { Link } from "react-router-dom";

export default function ProductDetails({ productDetails }) {
  const [similarProducts, setSimilarProducts] = useState([]);
  const [wishlisted, setWishlisted] = useState(false);
  const [selectedSize, setSelectedSize] = useState("S");
  const [wishlistId, setWishlistId] = useState(null);
  const [inCart, setInCart] = useState(false);
  const [cartId, setCartId] = useState(null);
  const { productId } = useParams();
  const carouselRef = useRef(null);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const token = localStorage.getItem("token");

  // Fetch similar products on component load
  useEffect(() => {
    const fetchProductByCategory = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/product-by-category`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ productId }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }

        const data = await response.json();
        setSimilarProducts(data);
      } catch (error) {
        console.error("Error fetching products by category:", error);
      }
    };

    fetchProductByCategory();
  }, [productId]);

  // Fetch wishlist on component load to determine if product is already in the wishlist
  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/wishlist/get`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        const wishlistItem = data.data.find(
          (item) => item.product_id === parseInt(productId)
        );

        if (wishlistItem) {
          setWishlisted(true);
          setWishlistId(wishlistItem.wishlist_id);
        } else {
          setWishlisted(false);
          setWishlistId(null);
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchWishlist();
  }, [productId, wishlisted, token]);

  // Fetch cartItem on component load to determine if product is already in the cart
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/cart/get`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();

        const cartItem = data.data.find(
          (item) => item.product_id === parseInt(productId)
        );

        if (cartItem) {
          setInCart(true);
          setCartId(cartItem.cart_id);
        } else {
          setInCart(false);
          setCartId(null);
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchCartItems();
  }, [productId, inCart, token]);

  // Add product to wishlist
  const handleAddWishlistItem = async (productId) => {
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/wishlist/add/${productId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to add to wishlist");
        }

        setWishlisted(true);
      } catch (error) {
        console.error("Error adding to wishlist:", error);
      }
    } else {
      alert("Please login to add to wishlist");
    }
  };

  // Remove product from wishlist
  const handleRemoveWishlistItem = async (wishlistId) => {
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/wishlist/delete/${wishlistId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 204) {
          setWishlisted(false);
        } else {
          console.error("Failed to delete the wishlist item");
        }
      } catch (error) {
        console.error("Error deleting wishlist item:", error);
      }
    }
  };

  // Add product to cart
  const handleAddCartItem = async () => {
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/cart/add/${productId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ quantity: 1, size: selectedSize }),
          }
        );

        if (!response.ok) {
          alert("Failed to add to cart");
        }

        setInCart(true);
      } catch (error) {
        console.error("Error adding to cart:", error);
      }
    } else {
      alert("Please login to add items to the cart.");
    }
  };

  const [mainImage, setMainImage] = useState({
    src: productDetails.images[0].src,
    alt: productDetails.images[0].alt,
  });

  const handleShare = async () => {
    const productUrl = window.location.href; // Current page URL
    const productTitle =
      "Check out this product from The Royal Man Ethnics Wardrobe!";
    const productDescription =
      "Explore high-quality, traditional wear from The Royal Man Ethnics Wardrobe.";

    if (navigator.share) {
      try {
        await navigator.share({
          title: productTitle,
          text: productDescription,
          url: productUrl,
        });
        console.log("Product shared successfully!");
      } catch (error) {
        console.error("Error sharing product:", error);
      }
    } else {
      // Fallback for browsers that don't support the Web Share API
      alert(
        "Sharing is not supported in this browser. Please copy the link: " +
          productUrl
      );
    }
  };
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const handleDragStart = (e) => e.preventDefault();

  const handleSlideChange = (e) => {
    setMainImageIndex(e.item);
  };

  return (
    <div className="mt-8 lg:px-20">
      <div className="pt-6">
        {/* Breadcrumb */}
        <nav aria-label="Breadcrumb">
          <ol className="flex items-center max-w-2xl px-4 mx-auto space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
            {productDetails.breadcrumbs.map((breadcrumb, index) => (
              <li key={`${breadcrumb.id}-${index}`}>
                <div className="flex items-center">
                  <a
                    href={breadcrumb.href}
                    className="mr-2 text-sm font-medium text-gray-900"
                  >
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    className="w-4 h-5 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <p
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                {productDetails.name}
              </p>
            </li>
          </ol>
        </nav>

        {/* Main Content */}
        <section className="grid grid-cols-1 px-4 pt-10 lg:grid-cols-2 gap-x-8 gap-y-10">
          {/* Image gallery */}
          <div className="flex flex-col items-center">
            {/* Main Carousel */}
            {productDetails.images && productDetails.images.length > 0 && (
              <AliceCarousel
                ref={carouselRef}
                items={productDetails.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    onDragStart={handleDragStart}
                    className="object-cover object-center w-full h-full max-w-[30rem] max-h-[35rem] rounded-lg"
                  />
                ))}
                activeIndex={mainImageIndex}
                onSlideChanged={handleSlideChange}
                disableButtonsControls
                disableDotsControls
                infinite
              />
            )}

            {/* Thumbnail Gallery */}
            <div className="flex flex-wrap justify-center space-x-5">
              {productDetails.images.map((image, index) => (
                <div
                  key={index}
                  className={`overflow-hidden rounded-lg aspect-h-2 aspect-w-3 max-w-[5rem] max-h-[5rem] mt-4 cursor-pointer ${
                    index === mainImageIndex ? "ring-2 ring-primary" : ""
                  }`}
                  onClick={() => {
                    setMainImageIndex(index);
                    carouselRef.current?.slideTo(index);
                  }}
                >
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="object-cover object-center w-full h-full"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Product Info */}
          <div className="max-w-2xl px-4 pb-16 lg:col-span-1 lg:max-w-7xl lg:pb-24">
            <h1 className="pt-1 text-lg text-gray-900 opacity-60 lg:text-xl">
              {productDetails.name}
            </h1>

            <div className="flex items-center mt-6 space-x-5 text-lg text-gray-900 lg:text-xl">
              <p className="font-semibold">{productDetails.price}.00</p>
              <p className="line-through opacity-50">
                ₹{productDetails.productPrice}
              </p>
              <p className="font-semibold text-green-600">
                {productDetails.discount}% Off
              </p>
            </div>

            {/* Sizes and Cart Options */}
            <form className="mt-10">
              {/* Display Sizes */}
              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900">Size</h3>
                <div className="flex items-center gap-4 mt-4">
                  {Object.entries(productDetails.sizes)
                    .filter(([size, available]) => available)
                    .map(([size], index) => (
                      <div
                        key={index}
                        className={`px-4 py-2 border rounded-md cursor-pointer ${
                          selectedSize === size ? "bg-[#E85826] text-white" : ""
                        }`}
                        onClick={() => handleSizeSelect(size)}
                      >
                        {size}
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex items-center mt-5">
                <div className="flex items-center gap-5 p-4 border cursor-pointer">
                  {/* <IoIosHeartEmpty
                    size={32}
                    
                  /> */}
                  {wishlisted ? (
                    <IoIosHeart
                      size={32}
                      color="red"
                      onClick={() => handleRemoveWishlistItem(wishlistId)}
                    />
                  ) : (
                    <IoIosHeartEmpty
                      size={32}
                      onClick={() => handleAddWishlistItem(productId)}
                    />
                  )}
                  {/* {token ? (wishlisted ? <IoIosHeart size={32} color="red" onClick={() => handleRemoveWishlistItem(wishlistId)}/> : <IoIosHeartEmpty size={32} onClick={() => handleAddWishlistItem(productId)}/>) : <IoIosHeartEmpty size={32} onClick={() => handleAddWishlistItem(productId)}/>} */}
                  <IoShareSocialOutline size={32} onClick={handleShare} />
                </div>
                <AddToCart
                  inCart={inCart}
                  handleAddCartItem={handleAddCartItem}
                />
              </div>
            </form>

            {/* Description */}
            <div className="py-10 lg:pt-6">
              <h3 className="sr-only">Description</h3>
              <p className="text-base text-gray-900">
                {productDetails.description}
              </p>

              <div className="mt-10">
                <h3 className="text-sm font-medium text-gray-900">
                  Highlights
                </h3>
                <ul className="pl-4 mt-4 space-y-2 text-sm list-disc">
                  {productDetails.highlights.map((highlight, index) => (
                    <li key={index} className="text-gray-600">
                      {highlight}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">Details</h2>
                <p className="mt-4 text-sm text-gray-600">
                  {productDetails.details}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Similar Products */}
        <section className="pt-10">
          <h1 className="py-5 text-[24px] font-bold ml-[3.5rem] mb-[-2rem]">
            Similar Products
          </h1>

          <div className="grid grid-cols-2 lg:grid-cols-3 p-[3rem] justify-center items-center gap-10">
            {similarProducts.map((similarProduct) => (
              <ProductCard
                key={similarProduct.product_id}
                product={similarProduct}
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
